<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" x-small text @click.stop="deleteDocument"
        ><v-icon small>mdi-delete</v-icon></v-btn
      >
    </template>
    <span> Delete </span>
    <confirm-modal ref="confirm"></confirm-modal>
  </v-tooltip>
</template>

<script>
import ConfirmModal from "../../Shared/confirm-modal";

export default {
  props: ["fileId"],
  components: {
    ConfirmModal,
  },
  methods: {
    deleteDocument: async function () {
      if (
        await this.$refs.confirm.open(
          "Are you sure you want to DELETE this document?",
          "Note: This action cannot be undone.",
          "Delete"
        )
      ) {
        this.$store.commit("uxModule/setShowLoader", true);
        await this.$store.dispatch("clientModule/deleteClientDocument", this.fileId);
        this.$store.commit("uxModule/setShowLoader", false);
      }
    },
  },
};
</script>
