<template>
  <v-data-table
    :headers="headers"
    :items="clients"
    :key="clients.length"
    @click:row="(item) => goToClient(item)"
    style="cursor: pointer"
    class="alt-shading"
  >
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Client Id", value: "clientId", align: "left" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Date Of Birth", value: "formattedDob" },
        { text: "OSIS", value: "formattedOsis" },
        { text: "NYEIS ID", value: "nyeisId" },
      ],
    };
  },
  computed: {
    clients() {
      return this.$store.state.clientModule.clients || [];
    },
  },
  methods: {
    async goToClient(event) {
      this.$router.push({
        name: "ClientDetails",
        params: { clientId: event.clientId },
      });
    },
  },
};
</script>
<style lang="css" scoped>
.alt-shading >>> tr:nth-child(even) {
  background: #ccc;
}
</style>