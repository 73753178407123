import * as api from "../Services/api";
import SessionDetails from "../../objs/SessionDetails";

export const state = {
  sessions: [],
};

export const getters = {
  getServiceCoordinationNotes: (state) => {
    return state.sessions.filter((item) => item.scNoteFileId != null);
  },
  getSessionNotes: (state) => {
    return state.sessions.filter((item) => item.sessionNoteFileId != null);
  },
};
export const mutations = {
  SET_SESSIONS(state, payload) {
    state.sessions = payload
      ? payload.map((item) => new SessionDetails(item))
      : [];
  },
  UPDATE_SC_SIGNATURE(state, payload) {
    state.sessions = state.sessions.map((item) => {
      return item.serviceCoordinationNoteId ===
        payload.serviceCoordinationNoteId
        ? new SessionDetails({
            ...item,
            scSignatureId: payload.scSignatureId,
          })
        : item;
    });
  },
  UPDATE_SESSION_NOTE_SIGNATURE(state, payload) {
    state.sessions = state.sessions.map((item) => {
      return item.sessionNoteId === payload.sessionNoteId
        ? new SessionDetails({
            ...item,
            supervisorSignatureId: payload.supervisorSignatureId,
          })
        : item;
    });
  },
  CLEAR_SESSIONS() {
    this.sessions = null;
  },
};
export const actions = {
  async signServiceCoordinationNote({ commit, state, rootState }, data) {
    const userSessionLogId =
      rootState.securityModule.userSessionLog.userSessionLogId || "";
    return await api
      .postQueryWithBody(
        "/SessionNote/SignServiceCoordinationNote",
        { serviceCoordinationNoteId: data.serviceCoordinationNoteId },
        {
          consentedAt: data.consentedAt,
          signatureURI: data.signatureURI,
          userSessionLogId: userSessionLogId,
        }
      )
      .then((response) => {
        if (response.success) {
          commit("UPDATE_SC_SIGNATURE", response.data);
        }
        return response;
      })
      .catch((error) => {
        return false;
      });
  },
  async signSessionNote({ commit, state, rootState }, data) {
    const userSessionLogId =
      rootState.securityModule.userSessionLog.userSessionLogId || "";
    return await api
      .postQueryWithBody(
        "/SessionNote/SignSessionNote",
        { sessionNoteId: data.sessionNoteId },
        {
          consentedAt: data.consentedAt,
          signatureURI: data.signatureURI,
          userSessionLogId: userSessionLogId,
        }
      )
      .then((response) => {
        if (response.success) {
          commit("UPDATE_SESSION_NOTE_SIGNATURE", response.data);
        }
        return response;
      })
      .catch((error) => {
        return false;
      });
  },
  async findByClient({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/FindByClient", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response;
      });
  },
  async findByServiceForProvider({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findByServiceForProvider", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response;
      });
  },
  async findByProviderAndDate({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/FindByProviderAndDate", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response;
      });
  },
  async findByProviderAndDateRange({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findByProviderAndDateRange", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response;
      });
  },
  async findByProviderAndClient({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findByProviderAndClient", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response;
      });
  },
  async findByDateOfService({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findByDateOfService", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response;
      });
  },
  async findBySupervisorAndDateRange({ commit }, data) {
    return await api
      .getQuery("/sessionDetails/findBySupervisorAndDateRange", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SESSIONS", response.data);
        }
        return response;
      });
  },
  clearSessions({ commit }) {
    commit("CLEAR_SESSIONS");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
