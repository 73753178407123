var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-switch',{attrs:{"label":_vm.isServiceCoordination
            ? 'Awaiting Signature'
            : 'Awaiting Supervisor Signature'},model:{value:(_vm.unsignedOnly),callback:function ($$v) {_vm.unsignedOnly=$$v},expression:"unsignedOnly"}})],1),_c('v-col',{class:_vm.isMobile ? null : 'pt-4',attrs:{"cols":"12","sm":"2"}},[_c('download-zip-button',{attrs:{"selectedFiles":_vm.selectedItems.map((item) =>
            _vm.isServiceCoordination ? item.scNoteFileId : item.sessionNoteFileId
          ),"zipFileName":_vm.zipFileName},on:{"clear":_vm.clearSelected}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm._notes.length,staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm._headers,"items":_vm._notes,"item-key":"noteId","search":_vm.search,"group-by":_vm._groupBy,"group-desc":"","dense":"","height":"100%","item-class":_vm.highlightSelectedRow,"show-select":""},on:{"click:row":_vm.loadFile},scopedSlots:_vm._u([{key:`header.data-table-select`,fn:function(){return undefined},proxy:true},{key:`group.header`,fn:function({ group, headers, toggle, isOpen, items }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.clientId ? _vm.isServiceCoordination ? items[0].formattedDateOfService : items[0].serviceName : items[0].clientName)+" ")])],1)]}},{key:`item.scSignatureId`,fn:function({ item }){return [(item.scSignatureId)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-signature")])]}},{key:`item.supervisorSignatureId`,fn:function({ item }){return [(
            item.requiresSupervisorSignature && item.supervisorSignatureId
          )?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-box-outline")]):(
            item.requiresSupervisorSignature && !item.supervisorSignatureId
          )?_c('v-icon',[_vm._v("mdi-signature")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),(_vm.selected)?_c('v-dialog',{attrs:{"width":"1000","scrollable":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!_vm.loading)?_c('v-card',{attrs:{"width":"100vw","height":"80vh"}},[(_vm.showSignaturePad)?_c('v-card-actions',{staticClass:"justify-center"},[_c('signature-pad',{on:{"saveSignature":_vm.saveSignature}})],1):_vm._e(),_c('iframe',{attrs:{"width":"100%","height":"100%","src":_vm.pdfFile}})],1):_c('v-card',{attrs:{"height":"750","width":"1000"}},[_c('v-overlay',{attrs:{"value":true,"absolute":true}},[_c('v-card-text',{staticClass:"d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"45"}}),_c('v-subheader',{staticClass:"text-h5"},[_vm._v("Processing...")])],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }