<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn x-small text @click.stop="openDialog"
              ><v-icon small>mdi-pencil</v-icon></v-btn
            >
          </span>
        </template>
        <span> Edit </span>
      </v-tooltip>
    </template>

    <v-card class="primary--text" v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Edit Document </v-card-title>
        <v-card-text class="pt-0">
          <validation-provider
            :rules="{ regex: /^[a-zA-Z0-9 _.-]+$/ }"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="fileName"
              label="File Name"
              prepend-icon="mdi-paperclip"
              :suffix="fileExtension"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
          <validation-provider :rules="{ required: true }" v-slot="{ errors }">
            <v-select
              v-model="documentTypeId"
              item-value="documentTypeId"
              item-text="name"
              prepend-icon="mdi-tag-outline"
              label="Document Type"
              :items="documentTypes"
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :dark="!loading"
            :disabled="loading"
            text
            @click="clear"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :dark="!loading && !invalid"
            :disabled="loading || invalid"
            text
            @click.once="save"
            >Save</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["documentType", "file"],
  data() {
    return {
      dialog: false,
      loading: false,
      documentTypeId: null,
      fileName: "",
      fileExtension: "",
    };
  },
  async mounted() {
    this.documentTypeId = this.documentType.documentTypeId;
    var lastIndex = this.file.fileName.lastIndexOf(".");
    this.fileName = this.file.fileName.slice(0, lastIndex);
    this.fileExtension = this.file.fileName.slice(lastIndex);
  },
  computed: {
    documentTypes() {
      return this.$store.state.optionsModule.documentTypes;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    async save() {
      this.loading = true;
      var success = await this.$store.dispatch(
        "clientModule/updateClientDocument",
        {
          fileId: this.file.fileId,
          documentTypeId: this.documentTypeId,
          fileName: this.fileName + this.fileExtension,
        }
      );
      this.clear();
      this.loading = false;
      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving changes.");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
    clear() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
