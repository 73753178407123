<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" :class="isMobile ? null : 'pt-4 px-4'">
        <upload-document-button
          :clientId="clientId"
        ></upload-document-button></v-col
      ><v-col cols="12" sm="2" :class="isMobile ? null : 'pt-4 px-4'"
        ><download-zip-button
          :selectedFiles="selectedItems.map((item) => item.fileId)"
          :zipFileName="zipFileName"
          @clear="clearSelected"
        ></download-zip-button
      ></v-col>
    </v-row>
    <v-col cols="12">
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="documents"
        item-key="fileId"
        :search="search"
        group-by="documentType.documentTypeId"
        @click:row="loadFile"
        show-select
        dense
        height="100%"
        style="cursor: pointer"
        :item-class="highlightSelectedRow"
        :key="documents.length"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <edit-document
            v-if="isEIAdmin"
            :documentType="item.documentType"
            :file="item"
          ></edit-document>
          <delete-document v-if="isAdmin" :fileId="item.fileId">
          </delete-document>
        </template>
        <template v-slot:[`header.data-table-select`]></template>
        <template
          v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
          ><td :colspan="headers.length">
            <v-btn @click="toggle" x-small icon :ref="group">
              <v-icon v-if="isOpen">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
            <span class="mx-5 font-weight-bold text-subtitle-2">
              {{ items[0].documentType.name }}
            </span>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-if="selected && canPreview"
      v-model="dialog"
      width="1000"
      :scrollable="false"
    >
      <v-card width="100vw" height="80vh">
        <iframe width="100%" height="100%" :src="url"></iframe>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selected && !canPreview" v-model="dialog" width="400">
      <v-card>
        <v-card-title class="d-flex align-center justify-center">
          <v-subheader class="text-h5">Preview not available</v-subheader>
        </v-card-title>
        <v-card-text class="d-flex align-center justify-center">
          <v-btn
            @click="downloadFile"
            class="text-decoration-underline"
            color="primary"
            text
            >Click Here to Download</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UploadDocumentButton from "../../Shared/upload-document-button";
import DownloadZipButton from "../../Shared/download-zip-btn";
import DeleteDocument from "./delete-document";
import EditDocument from "./edit-document";
import { downloadFileUrl } from "../../../utils/download-utils";

export default {
  components: {
    UploadDocumentButton,
    DownloadZipButton,
    DeleteDocument,
    EditDocument,
  },
  props: ["clientId"],
  data() {
    return {
      headers: [
        {
          text: "File Name",
          value: "fileName",
        },
        {
          text: "Uploaded At",
          value: "formattedUploadedAt",
        },
        {
          text: "Document Type",
          value: "documentType.name",
          align: " d-none",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      search: "",
      url: "",
      selected: null,
      selectedItems: [],
      dialog: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
    isEIAdmin() {
      return this.$store.getters["securityModule/isEIAdmin"];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    documents() {
      return this.$store.state.clientModule.documents;
    },
    zipFileName: function () {
      return `${this.clientName}_Documents.zip`;
    },
    clientName() {
      return this.$store.state.clientModule.selectedClient.fullName;
    },
    canPreview() {
      var extensions = [
        "application/pdf",
        "text/plain",
        "text/html",
        "image/jpeg",
        "image/png",
      ];
      return extensions.includes(this.selected.fileExtension);
    },
  },
  methods: {
    async loadFile(item) {
      this.url = null;
      this.selected = item;
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: item.fileId,
      });
      this.url = fileUrl;
      this.dialog = true;
    },
    clearSelected() {
      this.selectedItems = [];
    },
    highlightSelectedRow: function (row) {
      return this.selected?.fileId === row.fileId && "selectedRow";
    },
    downloadFile: function () {
      downloadFileUrl(this.url, this.selected.fileName);
    },
  },
};
</script>
<style lang="css">
.selectedRow {
  background: #ccc;
}
</style>