<template>
  <div>
    <v-form @submit.prevent="searchFiles">
      <v-row>
        <v-col cols="12" sm="9">
          <v-text-field
            label="File Name"
            v-model="fileName"
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            class="mx-6"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn small color="primary" type="submit">Find Files</v-btn>
          <v-btn small outlined class="mx-6" @click="clear">Clear </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      v-if="showTable"
      :headers="headers"
      :items="documents"
      :key="documents.length"
      @click:row="(item) => goToClient(item)"
      style="cursor: pointer"
      class="alt-shading"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileName: "",
      showTable: false,
      headers: [
        { text: "Filename", value: "fileName" },
        { text: "Date Uploaded", value: "formattedUploadedAt" },
        { text: "Document Type", value: "documentType.name" },
      ],
    };
  },
  computed: {
    documents() {
      var files = this.$store.state.clientModule.documents || [];
      return files.filter((x) => x.clientId != null);
    },
  },
  methods: {
    async goToClient(event) {
      this.$router.push({
        name: "ClientDetails",
        params: { clientId: event.clientId },
      });
    },
    clear() {
      this.fileName = "";
    },
    async searchFiles() {
      this.$store.commit("uxModule/setShowLoader", true);
      let success = false;
      success = await this.$store.dispatch("clientModule/searchFiles", {
        fileName: this.fileName,
      });
      this.$store.commit("uxModule/setShowLoader", false);
      if (success) {
        this.showTable = true;
      } else {
        this.$store.commit("uxModule/setSnackbarMsg", "Error Retrieving Files");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.clear();
    },
  },
};
</script>

<style lang="css" scoped>
.alt-shading >>> tr:nth-child(even) {
  background: #ccc;
}
</style>