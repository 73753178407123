import DocumentType from "./DocumentType";
import { formatDate } from "../utils/format-utils";

export default class File {
  fileId?: number | null;
  fileExtension?: string | null;
  fileName?: string | null;
  uploadedAt?: string | null;
  documentTypeId?: number | null;
  documentType?: DocumentType | null;
  categoryId?: number | null;
  clientId?: number | null;

  public constructor(params: File = {} as File) {
    let {
      fileId = 0,
      fileExtension = "",
      fileName = "",
      uploadedAt = "",
      documentTypeId = null,
      documentType = null,
      categoryId = null,
      clientId = null,
    } = params;
    this.fileId = fileId;
    this.fileExtension = fileExtension;
    this.fileName = fileName;
    this.uploadedAt = uploadedAt;
    this.documentTypeId = documentTypeId;
    this.documentType = documentType && new DocumentType(documentType);
    this.categoryId = categoryId;
    this.clientId = clientId;
  }

  get formattedUploadedAt() {
    return formatDate(this.uploadedAt);
  }
}
