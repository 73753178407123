<template>
  <div>
    <v-row v-if="isEIAdmin && Boolean(schoolId)">
      <v-spacer></v-spacer>
      <v-switch
        v-model="editMode"
        label="Edit Mode"
        dense
        class="mx-4"
      ></v-switch>
    </v-row>
    <v-card outlined>
      <v-card-title>
        <v-icon class="mr-2">mdi-account-outline</v-icon> Profile
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="form.schoolName"
                label="School Name"
                :readonly="!editMode"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="2">
            <v-checkbox
              v-model="form.summerCamp"
              label="Summer Camp"
              :readonly="!editMode"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="form.team"
              label="Team"
              :items="teams"
              :item-text="
                (item) => `${item.leaderFirstName} ${item.leaderLastName}`
              "
              return-object
              :clearable="editMode"
              :readonly="!editMode"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.schoolCode"
              label="School Code"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.secondarySchoolCode"
              label="Secondary School Code"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-2">mdi-map-marker-outline</v-icon> Address
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="5">
            <v-text-field
              v-model="form.streetAddress"
              label="Street Address"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.city"
              label="City"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              :items="usStates"
              v-model="form.state"
              label="State"
              :readonly="!editMode"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.zipCode"
              label="ZipCode"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.districtId"
              label="District"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.secondaryDistrictId"
              label="Secondary District ID"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.schoolBoro"
              label="School Boro"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.secondarySchoolBoro"
              label="Secondary School Boro"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-2">mdi-phone-outline</v-icon> Contact
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.contact"
              label="Contact"
              :readonly="!editMode"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.emailAddress"
              label="Email Address"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.phone"
              label="Phone"
              v-mask="maskPhone"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.phoneExtension"
              label="Extension"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.secondaryPhone"
              label="Secondary Phone"
              v-mask="maskPhone"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.secondaryPhoneExtension"
              label="Secondary Phone Extension"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.fax"
              label="Fax"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-2">mdi-comment-text-multiple-outline</v-icon> Notes
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
              v-model="form.notes"
              label="Notes"
              rows="1"
              autoGrow
              :readonly="!editMode"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" text>Cancel</v-btn>
        <v-btn @click="save" disabled color="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import School from "../../../objs/School";
export default {
  props: ["schoolId"],
  data() {
    return {
      form: new School(),
      dialog: false,
      editMode: !Boolean(this.schoolId),
      maskPhone: "(###) ###-####",
    };
  },
  computed: {
    isEIAdmin() {
      return this.$store.getters["securityModule/isEIAdmin"];
    },
    teams() {
      return this.$store.state.optionsModule.teams;
    },
    usStates() {
      return this.$store.state.optionsModule.usStates;
    },
  },
  mounted() {
    if (this.schoolId) {
      this.form = {
        ...this.$store.getters["optionsModule/getSchoolById"](this.schoolId),
      };
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: "Schools" });
    },
    async save() {
      let success = false;
      if (this.schoolId) {
        success = await this.$store.dispatch(
          "optionsModule/updateSchool",
          this.form
        );
      } else {
        success = await this.$store.dispatch(
          "optionsModule/createSchool",
          this.form
        );
      }
      if (success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "School saved successfully"
        );
      } else {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving school");
      }
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>