export default class DocumentType {
  documentTypeId?: number | null;
  name?: string | null;

  public constructor(params: DocumentType = {} as DocumentType) {
    let { documentTypeId = 0, name = "" } = params;
    this.documentTypeId = documentTypeId;
    this.name = name;
  }
}
