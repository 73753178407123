import mgr from "../../security/security.js";
import * as api from "../Services/api";
import UserProfile from "../../objs/UserProfile";
import UserSessionLog from "../../objs/UserSessionLog";

export const state = {
  isAuthenticated: false,
  user: "",
  requiresAuth: false,
  hasAccessToApp: true,
  userProfile: new UserProfile(),
  userSessionLog: new UserSessionLog(),
};

export const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setRequiresAuth(state, payload) {
    state.requiresAuth = payload;
  },
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  setHasAccessToApp(state, payload) {
    state.hasAccessToApp = payload;
  },
  setUserProfile(state, payload) {
    let userProfile = new UserProfile({
      id: payload.id,
      userName: payload.userName,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      legacySystemId: payload.legacySystemId,
      roles: payload.roles,
    });

    state.userProfile = userProfile;
  },
  setUserSessionLog(state, payload) {
    state.userSessionLog = new UserSessionLog(payload);
  },
};

export const getters = {
  hasUserRole: (state) => (role) => {
    return state.userProfile.roles.includes(role);
  },
  isAdmin(state, getters) {
    return getters.hasUserRole("AdminRole");
  },
  isEIAdmin(state, getters) {
    return getters.hasUserRole("EIAdmin") || getters.isAdmin;
  },
  isServiceCoordinator(state, getters) {
    return getters.hasUserRole("ServiceCoordinator") || getters.isEIAdmin;
  },
  isProvider(state, getters) {
    return (
      !getters.hasUserRole("EIAdmin") &&
      !getters.hasUserRole("ServiceCoordinator") &&
      !getters.hasUserRole("AdminRole")
    );
  },
};

export const actions = {
  async shallowAuthenticate({ commit, state, dispatch }) {
    console.log("shallow authenticate start");
    await mgr
      .signinSilent()
      .then(async () => {
        let user = await mgr.getUser();
        if (!!user && !user.expired) {
          commit("setUser", user);
          dispatch("getUserAccess");
          await dispatch("createOrUpdateUserSessionLog");
          // load whatever user data is specific to the application here
          if (state.userProfile.id === "") {
            await dispatch("getUserProfile");
          }
          commit("setIsAuthenticated", true);
          console.log("shallow authenticate end");
        } else {
          commit("setIsAuthenticated", false);
        }
      })
      .catch(async () => {
        console.log("shallow authenticate failed");
        commit("setIsAuthenticated", false);
        await dispatch("authenticate");
      });
  },
  async authenticate({ commit, state, dispatch }, returnPath) {
    console.log("authenticate start");
    commit("uxModule/setShowLoader", true, {
      root: true,
    });
    let user = await mgr.getUser();
    if (!!user && !user.expired) {
      commit("setUser", user);
      dispatch("getUserAccess");
      // load whatever user data is specific to the application here
      if (state.userProfile.id === "") {
        await dispatch("getUserProfile");
      }
      commit("setIsAuthenticated", true);
    } else {
      await (returnPath
        ? mgr.signinRedirect({ state: returnPath })
        : mgr.signinRedirect());
    }

    commit("uxModule/setShowLoader", false, {
      root: true,
    });
    console.log("authenticate end");
  },
  async logout({ commit, state, dispatch }) {
    await dispatch("createOrUpdateUserSessionLog", new Date());
    commit("setIsAuthenticated", false);
    await mgr.signoutRedirect();
  },
  async createOrUpdateUserSessionLog({ commit, state }, data) {
    console.log("createOrUpdateUserSessionLog start");
    await api
      .postQuery("/auditTrail/createOrUpdateUserSessionLog", {
        sessionStart: new Date(),
        sessionEnd: data ?? new Date(state.user?.expires_at * 1000),
      })
      .then((response) => {
        if (response.success) {
          commit("setUserSessionLog", response.data);
        }
      });
    console.log("createOrUpdateUserSessionLog end");
  },
  async getUserAccess({ commit, state }) {
    return api
      .getUserAccess({
        clientId: mgr.settings.client_id,
        userId: state.user?.profile?.sub,
      })
      .then((response) => {
        if (response.success) {
          commit("setHasAccessToApp", response.data);
        }
      });
  },
  async getUserProfile({ commit, state }) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });

    let userId = state.user?.profile?.sub;

    await api
      .getUser(userId)
      .then((response) => {
        if (response.success) {
          commit("setUserProfile", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });

    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
