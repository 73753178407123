import { formatOsis, formatDate } from "../utils/format-utils";

export default class ReportAssignmentDetails {
  reportAssignmentId?: number;
  clientId?: number | null;
  clientName?: string | null;
  clientOsis?: string | null;
  clientDistrictId?: number | null;
  clientDateOfBirth?: Date | null;
  clientEmailAddress?: string | null;
  programId?: number | null;
  serviceId?: number | null;
  service?: string | null;
  teamId?: number | null;
  teamName?: string | null;
  districtAdminId?: number | null;
  districtAdminName?: string | null;
  districtAdminEmailAddress?: string | null;
  providerName?: string | null;
  reportTypeId?: number | null;
  reportTypeName?: string | null;
  dueDate?: Date | null;
  status: string;
  reportAssignmentFileId?: number | null;
  fileId?: number | null;
  fileName?: string | null;
  public constructor(
    params: ReportAssignmentDetails = {} as ReportAssignmentDetails
  ) {
    let {
      reportAssignmentId = 0,
      clientId = null,
      clientName = "",
      clientOsis = "",
      clientDistrictId = null,
      clientDateOfBirth = null,
      clientEmailAddress = "",
      programId = null,
      serviceId = null,
      service = null,
      teamId = null,
      teamName = "",
      districtAdminId = null,
      districtAdminName = "",
      districtAdminEmailAddress = "",
      providerName = "",
      reportTypeId = null,
      reportTypeName = "",
      dueDate = null,
      status = "",
      reportAssignmentFileId = null,
      fileId = null,
      fileName = "",
    } = params;

    this.reportAssignmentId = reportAssignmentId;
    this.clientId = clientId;
    this.clientName = clientName;
    this.clientOsis = clientOsis && formatOsis(clientOsis);
    this.clientDateOfBirth = clientDateOfBirth;
    this.clientDistrictId = clientDistrictId;
    this.clientEmailAddress = clientEmailAddress;
    this.programId = programId;
    this.serviceId = serviceId;
    this.service = service;
    this.teamId = teamId;
    this.teamName = teamName;
    this.districtAdminId = districtAdminId;
    this.districtAdminName = districtAdminName;
    this.districtAdminEmailAddress = districtAdminEmailAddress;
    this.providerName = providerName;
    this.reportTypeId = reportTypeId;
    this.reportTypeName = reportTypeName;
    this.dueDate = dueDate;
    this.status = status;
    this.reportAssignmentFileId = reportAssignmentFileId;
    this.fileId = fileId;
    this.fileName = fileName;
  }

  get formattedDueDate() {
    return formatDate(this.dueDate);
  }
}
