<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        small
        color="primary"
        @click="dialog = true"
        ><v-icon left>mdi-upload</v-icon>Upload File</v-btn
      >
    </template>

    <v-card class="primary--text" v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Upload File </v-card-title>
        <v-card-text @drop.prevent="addDropFile" @dragover.prevent class="pt-0">
          <validation-provider
            ref="fileInputRef"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <v-file-input
              v-model="selectedFiles"
              prepend-icon="mdi-paperclip"
              label="Select File(s)"
              single-line
              multiple
              chips
              :error-messages="errors"
            ></v-file-input>
          </validation-provider>
          <validation-provider :rules="{ required: true }" v-slot="{ errors }">
            <v-select
              v-model="documentType"
              item-value="documentTypeId"
              item-text="name"
              prepend-icon="mdi-tag-outline"
              label="Document Type"
              :items="documentTypes"
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :dark="!loading"
            :disabled="loading"
            text
            @click="clear"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :dark="!loading && !invalid"
            :disabled="loading || invalid"
            text
            @click.once="upload"
            >Save</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["clientId"],
  data() {
    return {
      dialog: false,
      selectedFiles: [],
      documentType: null,
      loading: false,
    };
  },
  computed: {
    documentTypes: function () {
      return this.$store.state.optionsModule.documentTypes;
    },
  },
  methods: {
    addDropFile(e) {
      this.selectedFiles.push(...Array.from(e.dataTransfer.files));
      this.$refs.fileInputRef.validate();
    },
    async upload() {
      this.loading = true;
      await this.$store.dispatch("clientModule/uploadFilesForClient", {
        clientId: this.clientId,
        documentTypeId: this.documentType,
        files: this.selectedFiles,
      });
      this.loading = false;
      this.clear();
    },
    clear() {
      this.$refs.form.reset();
      this.selectedFiles = [];
      this.documentType = null;
      this.dialog = false;
    },
  },
};
</script>
