<template>
  <v-tabs>
    <v-tab v-if="Boolean(client)">Profile</v-tab>
    <!-- <v-tab v-if="Boolean(client)"
      >Schools</v-tab
    > -->
    <v-tab v-show="Boolean(client)">Client Chart</v-tab>
    <v-tab-item v-if="Boolean(client)">
      <client-profile :client="client"></client-profile>
    </v-tab-item>
    <!-- <v-tab-item v-if="Boolean(client)">
      <client-schools-table></client-schools-table>
    </v-tab-item> -->
    <v-tab-item>
      <client-chart :client="client"></client-chart>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import ClientProfile from "../Details/client-profile";
// import ClientSchoolsTable from "../Chart/client-schools-table";
import ClientChart from "./client-chart";

export default {
  props: ["client"],
  components: {
    ClientProfile,
    // ClientSchoolsTable,
    ClientChart,
  },
};
</script>