<template>
  <div>
    <session-lookup
      v-if="isEIAdmin"
      :expanded="expanded"
      @showTable="displayTable()"
      @toggleExpand="toggleExpand()"
    ></session-lookup>
    <tabs v-if="showTable"></tabs>
  </div>
</template>

<script>
import SessionLookup from "./session-lookup";
import Tabs from "../Client/Details/tabs";

export default {
  components: {
    SessionLookup,
    Tabs,
  },
  data: function () {
    return {
      expanded: true,
      showTable: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("sharedModule/getProviderId", null, {
      root: true,
    });
    if (!this.isEIAdmin) {
      // admins do not need a loader - they just see the session-lookup
      this.$store.commit("uxModule/setShowLoader", true);
      // if ClientOptions didn't finish yet, this will go thru the watcher when it does
      // if ClientOptions is finished, this will go thru the mounted()
      if (this.serviceId != null) {
        await this.findByServiceForProvider();
        this.showTable = true;
        this.$store.commit("uxModule/setShowLoader", false);
      }
    }
  },
  computed: {
    providerId() {
      return this.$store.state.sharedModule.providerId;
    },
    isEIAdmin() {
      return this.$store.getters["securityModule/isEIAdmin"];
    },
    serviceId() {
      return this.$store.getters["optionsModule/getServiceId"]("SC");
    },
  },
  methods: {
    displayTable() {
      this.expanded = false;
      this.showTable = true;
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    async findByServiceForProvider() {
      await this.$store.dispatch("sessionsModule/findByServiceForProvider", {
        providerId: this.providerId,
        serviceId: this.serviceId,
      });
    },
  },
  watch: {
    async serviceId() {
      if (!this.isEIAdmin) {
        await this.findByServiceForProvider();
        this.showTable = true;
        this.$store.commit("uxModule/setShowLoader", false);
      }
    },
  },
};
</script>