<template>
  <div>
    <v-select
      v-show="isMobile"
      :items="_tabs"
      item-text="title"
      item-value="id"
      v-model="active_tab"
      solo
      outlined
      dense
      prepend-inner-icon="mdi-folder-open-outline"
    >
      <template v-slot:item="{ item }">
        <span :class="item.styleRule ? 'font-weight-bold' : null">{{
          item.title
        }}</span
        ><v-badge
          :content="item.badgeContent"
          :value="Boolean(item.badgeContent)"
          color="error"
          inline
        ></v-badge>
      </template>
    </v-select>
    <v-tabs
      vertical
      v-model="active_tab"
      class="my-4 left"
      active-class="border"
    >
      <v-tab v-show="!isMobile" v-for="tab in _tabs" :key="tab.id"
        ><span :class="tab.styleRule ? 'font-weight-black' : null"
          ><v-icon left
            >{{
              active_tab == tab.id
                ? "mdi-folder-open-outline"
                : "mdi-folder-outline"
            }} </v-icon
          >{{ tab.title }}</span
        ><v-badge
          :content="tab.badgeContent"
          :value="Boolean(tab.badgeContent)"
          color="error"
          bordered
        ></v-badge
      ></v-tab>
      <v-tab-item v-for="tab in _tabs" :key="tab.id" class="ml-2">
        <component
          v-model="tab.id"
          :is="tab.component"
          :clientId="Boolean(client) ? client.clientId : null"
          :isServiceCoordination="tab.title == 'SC Notes'"
          :notes="
            tab.title == 'SC Notes'
              ? serviceCoordinationNotes
              : tab.title == 'Session Notes'
              ? sessionNotes
              : null
          "
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import NotesTable from "./notes-table";
import GapsInService from "./gaps-in-service";
import ProgressReports from "./progress-reports";
import OtherDocuments from "./other-documents.vue";

export default {
  props: ["client"],
  data() {
    return {
      active_tab: 0,
    };
  },
  components: {
    NotesTable,
    GapsInService,
    ProgressReports,
    OtherDocuments,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    _tabs() {
      var tabs = [
        {
          title: "SC Notes",
          showTab: !this.isProvider, //EIAdmin/ServiceCoordinator can always view
          styleRule: Boolean(this.serviceCoordinationNotes.length),
          badgeContent: this.numAwaitingSCSignature,
          component: "NotesTable",
        },
        {
          title: "Session Notes",
          showTab: this.isEIAdmin || Boolean(this.client), // EIAdmin can always view and anyone can view when within Client Chart
          styleRule: Boolean(this.sessionNotes.length),
          badgeContent: this.numAwaitingSupervisorSignature,
          component: "NotesTable",
        },
        {
          title: "Gaps in Service",
          showTab: Boolean(this.client), //anyone can view when within Client Chart
          styleRule: Boolean(this.gapsInService.length),
          badgeContent: null,
          component: "GapsInService",
        },
        {
          title: "Progress Reports",
          showTab: Boolean(this.client), //anyone can view when within Client Chart
          styleRule: Boolean(this.progressReports.length),
          badgeContent: null,
          component: "ProgressReports",
        },
        {
          title: "Other Documents",
          showTab: !this.isProvider && Boolean(this.client), //EIAdmin/ServiceCoordinator can view when within Client Chart
          styleRule: Boolean(this.documents.length),
          badgeContent: null,
          component: "OtherDocuments",
        },
      ];
      tabs = tabs.filter((item) => item.showTab);
      // set tab id on filtered tabs starting from 0 so that hidden tabs don't have an id
      // to use for active_tab
      var id = 0;
      for (var item of tabs) {
        item.id = id;
        id++;
      }
      return tabs;
    },
    serviceCoordinationNotes() {
      return this.$store.getters["sessionsModule/getServiceCoordinationNotes"];
    },
    sessionNotes() {
      return this.$store.getters["sessionsModule/getSessionNotes"];
    },
    gapsInService() {
      return this.$store.state.clientModule.gapsInService;
    },
    progressReports() {
      return this.$store.state.clientModule.reportAssignments;
    },
    documents() {
      return this.$store.state.clientModule.documents ?? [];
    },
    numAwaitingSCSignature() {
      return this.serviceCoordinationNotes.filter(
        (item) => item.providerId === this.providerId && !item.scSignatureId
      ).length;
    },
    numAwaitingSupervisorSignature() {
      return this.sessionNotes.filter(
        (item) =>
          item.supervisorId === this.providerId && !item.supervisorSignatureId
      ).length;
    },
    isEIAdmin() {
      return this.$store.getters["securityModule/isEIAdmin"];
    },
    isProvider() {
      return this.$store.getters["securityModule/isProvider"];
    },
    providerId() {
      return this.$store.state.sharedModule.providerId;
    },
  },
};
</script>

<style lang="css">
.left .v-tab {
  justify-content: left;
  min-width: 200px;
}
.border {
  border: 1px solid #1c344b;
  box-shadow: 0 0 10px #1c344b;
}
</style>