<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" :class="isMobile ? null : 'pt-4'">
        <download-zip-button
          :selectedFiles="selectedItems.map((item) => item.fileId)"
          :zipFileName="zipFileName"
          @clear="clearSelected"
        ></download-zip-button
      ></v-col>
    </v-row>
    <v-col cols="12">
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="progressReports"
        item-key="reportAssignmentFileId"
        :search="search"
        group-desc
        @click:row="loadFile"
        dense
        height="100%"
        style="cursor: pointer"
        :item-class="highlightSelectedRow"
        show-select
        :key="progressReports.length"
      >
        <template v-slot:[`header.data-table-select`]></template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-if="selected && canPreview"
      v-model="dialog"
      width="1000"
      :scrollable="false"
    >
      <v-card width="100vw" height="80vh">
        <iframe width="100%" height="100%" :src="url"></iframe>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selected && !canPreview" v-model="dialog" width="400">
      <v-card>
        <v-card-title class="d-flex align-center justify-center">
          <v-subheader class="text-h5">Preview not available</v-subheader>
        </v-card-title>
        <v-card-text class="d-flex align-center justify-center">
          <v-btn
            @click="downloadFile"
            class="text-decoration-underline"
            color="primary"
            text
            >Click Here to Download</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DownloadZipButton from "../../Shared/download-zip-btn";
import { downloadFileUrl } from "../../../utils/download-utils";

export default {
  props: ["clientId"],
  components: { DownloadZipButton },
  data() {
    return {
      headers: [
        {
          text: "Provider Name",
          value: "providerName",
        },
        {
          text: "Service",
          value: "service",
        },
        {
          text: "Report Type",
          value: "reportTypeName",
        },
        {
          text: "Due Date",
          value: "formattedDueDate",
        },
      ],
      search: "",
      url: "",
      selected: null,
      selectedItems: [],
      dialog: false,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    progressReports() {
      return this.$store.state.clientModule.reportAssignments;
    },
    zipFileName: function () {
      var clientName = this.$store.state.clientModule?.selectedClient?.fullName;
      return `${clientName}_ProgressReports.zip`;
    },
    canPreview() {
      var lastIndex = this.selected.fileName.lastIndexOf(".");
      var fileExtension = this.selected.fileName.slice(lastIndex);
      return fileExtension == ".pdf";
    },
  },
  methods: {
    async loadFile(item) {
      this.url = null;
      this.selected = item;
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: item.fileId,
      });
      this.url = fileUrl;
      this.dialog = true;
    },
    highlightSelectedRow: function (row) {
      return (
        this.selected?.reportAssignmentFileId === row.reportAssignmentFileId &&
        "selectedRow"
      );
    },
    clearSelected() {
      this.selectedItems = [];
    },
    downloadFile: function () {
      downloadFileUrl(this.url, this.selected.fileName);
    },
  },
  watch: {
    progressReports() {
      this.selected = null;
    },
  },
};
</script>
<style lang="css">
.selectedRow {
  background: #ccc;
}
</style>