import * as api from "../Services/api";
import School from "../../objs/School";
import Provider from "../../objs/Provider";
import Client from "../../objs/Client";
import Team from "../../objs/Team";
import Semester from "../../objs/Semester";
import Program from "../../objs/Program";
import Service from "../../objs/Service";
import DocumentType from "../../objs/DocumentType";

export const state = {
  genders: [],
  languages: [],
  caregiverRelationships: [],
  eiStaffingTeams: [],
  phoneTypes: [],
  usStates: ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'],
  physicians: [],
  schools: [],
  teams: [],
  semesters: [],
  programs: [],
  services: [],
  providers: [],
  clients: [],
  supervisors: [],
  documentTypes: [],
};
export const getters = {
  getFilteredSchoolsByIsSummerCamp: (state) => (isSummerCamp) => {
    return state.schools.filter((item) => item.summerCamp === isSummerCamp);
  },
  getSchoolById: (state) => (schoolId) => {
    return state.schools.find((item) => item.schoolId == schoolId);
  },
  getServiceId: (state) => (serviceCode) => {
    return state.services.find((item) => item.code === serviceCode)?.serviceId;
  },
};

export const mutations = {
  SET_OPTIONS(state, payload) {
    state.genders = payload.genders;
    state.languages = payload.languages;
    state.caregiverRelationships = payload.caregiverRelationships;
    state.eiStaffingTeams = payload.eiStaffingTeams;
    state.phoneTypes = payload.phoneTypes;
    state.physicians = payload.physicians;
    state.schools = payload.schools.map((item) => new School(item));
    state.teams = payload.teams.map((item) => new Team(item));
    state.semesters = payload.semesters.map((item) => new Semester(item));
    state.programs = payload.programs.map((item) => new Program(item));
    state.services = payload.services.map((item) => new Service(item));
    state.supervisors = payload.supervisors.map((item) => new Provider(item));
    state.documentTypes = payload.documentTypes.map(
      (item) => new DocumentType(item)
    );
  },
  SET_PROVIDERS(state, payload) {
    state.providers = payload
      .map((item) => new Provider(item))
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
  SET_CLIENTS(state, payload) {
    state.clients = payload
      .map((item) => new Client(item))
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
  ADD_SCHOOL(state, payload) {
    state.schools.push(new School(payload));
  },
  UPDATE_SCHOOL(state, payload) {
    state.schools = state.schools.map((item) => {
      return item.schoolId == payload.schoolId ? new School(payload) : item;
    });
  },
};
export const actions = {
  async getClientOptions({ commit }) {
    return await api.get("/options/getClientOptions").then((response) => {
      if (response.success) {
        commit("SET_OPTIONS", response.data);
      }
    });
  },
  async getAllProviders({ commit }) {
    return await api.get("/provider/getAllProviders").then((response) => {
      if (response.success) {
        commit("SET_PROVIDERS", response.data);
      }
    });
  },
  async getAllClients({ commit }) {
    return await api.get("/options/getAllClients").then((response) => {
      if (response.success) {
        commit("SET_CLIENTS", response.data);
      }
    });
  },
  async createSchool({ commit }, data) {
    return await api.post("/school/createSchool", data).then((response) => {
      if (response.success) {
        commit("ADD_SCHOOL", response.data);
      }
      return response.success;
    });
  },
  async updateSchool({ commit }, data) {
    return await api.post("/school/updateSchool", data).then((response) => {
      if (response.success) {
        commit("UPDATE_SCHOOL", response.data);
      }
      return response.success;
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
