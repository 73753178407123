import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import store from "../store/index";
Vue.use(VueRouter);

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const checkUserAccess = async (to, from, next) => {
  if (to.name !== "Error" && !store.state.securityModule.hasAccessToApp) {
    next({
      path: "/error",
    });
  } else if (to.meta.admin || to.meta.sc) {
    if (to.meta.admin && store.getters["securityModule/isEIAdmin"]) {
      next();
    } else if (
      to.meta.sc &&
      store.getters["securityModule/isServiceCoordinator"]
    ) {
      next();
    } else {
      next({
        path: "/error",
        query: { page: to.name, roles: [] },
      });
    }
  } else {
    next();
  }
};

router.beforeEach(async (to, from, next) => {
  // await store.dispatch("securityModule/shallowAuthenticate");
  if (store.state.securityModule.isAuthenticated) {
    checkUserAccess(to, from, next);
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.commit("securityModule/setRequiresAuth", true);
    await store.dispatch("securityModule/authenticate", to.path).then(() => {
      checkUserAccess(to, from, next);
    });
  } else {
    store.commit("securityModule/setRequiresAuth", false);
    next();
  }
});

export const showItem = function (item) {
  if (item.onNavBar == true) {
    if (item.meta.admin || item.meta.sc) {
      if (item.meta.admin && store.getters["securityModule/isEIAdmin"]) {
        return item;
      } else if (
        item.meta.sc &&
        store.getters["securityModule/isServiceCoordinator"]
      ) {
        return item;
      } else {
        return false;
      }
    } else {
      return item;
    }
  } else {
    return false;
  }
};

export default router;
